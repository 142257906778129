import { firebaseDB, db } from "./index";
import { getDocumentMetadata, commitBatch, removeUndefined } from "./helper";
const setting = {
  updateSetting: async function (data) {
    let batch = db.batch();

    const documentMetadataUpdate = getDocumentMetadata(0);
    let settingData = removeUndefined(data);

    batch.update(firebaseDB.globalCollection().doc("optSetting"), {
      ...settingData,
      ...documentMetadataUpdate,
    });

    return await commitBatch(batch);
  },

  updateDataDashSetting: async function (data) {
    let batchData = db.batch();

    const documentMetadataUpdateData = getDocumentMetadata(0);
    let settingData = removeUndefined(data);

    batchData.update(firebaseDB.globalCollection().doc("dataDashboard"), {
      ...settingData,
      ...documentMetadataUpdateData,
    });

    return await commitBatch(batchData);
  },
};

export default setting;
