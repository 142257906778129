import { user } from "../actionTypes";
import modules from "../../helper/modules";

const initialFormState = {
  id: undefined,
  publicID: undefined,
  name: undefined,
  email: undefined,
  password: undefined,
  status: undefined,
  remark: undefined,
  assignedStock: undefined,
  assignedCustomer: [],
  assignedSaleStock: [],
  branch: undefined,
  department: undefined,
  lastLoginTime: undefined,
};

const initialPermState = modules.reduce((obj, item) => {
  return {
    ...obj,
    [item.module]: 0,
  };
}, {});

const initialState = {
  gotoHome: false,
  gotoLogin: false,
  user: null,
  accessPerm: JSON.parse(JSON.stringify(initialPermState)),
  attachID: null,
  numberPlate: null,
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,

  permissions: JSON.parse(JSON.stringify(initialPermState)),
  createUserToggle: false,
  isCreateScrapperUser: false,
  addAssignedUserToggle: false,
  addAssignedUserToSaleStockToggle: false,
  addAssignedUserToCustomerToggle: false,
  updateUserToggle: false,
  deleteUserToggle: false,
  detailUserToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case user.GOTO_HOME:
    case user.LOGIN: {
      return { ...state, gotoHome: true };
    }
    case user.FETCH: {
      const { user } = action.payload;
      return { ...state, user };
    }
    case user.GOTO_LOGIN: {
      return { ...state, gotoLogin: true };
    }
    case user.LOGIN_NEW_PASSWORD: {
      return { ...state, loginNewPassword: true };
    }
    case user.LOGOUT:
    case user.RESET_PARAM: {
      return { ...state, ...initialState };
    }
    case user.HANDLE_CHANGE_CHOOSE_USER_PRIVILEGE_TYPE: {
      const { privilegeType } = action.payload;
      return { ...state, privilegeType };
    }
    case user.LIST_USER: {
      const { tableItems, start, startSnapshot, query, searching } =
        action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case user.GET_USER_COUNTER: {
      const { totalUser, userCounter } = action.payload;
      return { ...state, totalUser, userCounter };
    }
    case user.CREATE_USER: {
      const { result } = action.payload;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case user.UPDATE_USER: {
      const { result } = action.payload;
      if (state.tableItems.length !== 0) {
        var newTableItems = [...state.tableItems];
        var index = newTableItems.findIndex((element) => {
          return element.id == result.id;
        });
        result.publicID = newTableItems[index].publicID;
        newTableItems[index] = result;
        return {
          ...state,
          tableItems: newTableItems,
        };
      } else {
        return {
          ...state,
          tableItems: [],
        };
      }
    }
    case user.HANDLE_ADD_USER_TO_TABLE: {
      const {
        assignedStock,
        assignedCustomer,
        branch,
        department,
        email,
        id,
        name,
        nameLower,
        permissions,
        remark,
        publicID,
        status,
        lastLoginTime
      } = action.payload;
      var newTableItems = [...state.tableItems];
      newTableItems.push({
        assignedStock:
          typeof assignedStock === "object"
            ? assignedStock.assignedStock
            : assignedStock,
        assignedCustomer:
          typeof assignedCustomer === "object"
            ? assignedCustomer.assignedCustomer
            : assignedCustomer,
        branch,
        department,
        email,
        id,
        name,
        nameLower,
        permissions,
        remark,
        status,
        publicID,
        lastLoginTime
      });
      return {
        ...state,
        tableItems: newTableItems,
      };
      // const { key, value, userID } = action.payload;
      // console.log("newTableItems: ", newTableItems);
      // var index = newTableItems.findIndex((element) => {
      //   console.log("element.id: ", element.id, ", userID: ", userID);
      //   return element.id == userID;
      // });
      // // newTableItems[index].publicID;
      // newTableItems[index].assignedStock.push(value);
      // console.log(newTableItems[index].assignedStock);
      // // return {
      // //   ...state,
      // //   tableItems: newTableItems,
      // // };
      // console.log("key:", key);
      // console.log("value:", value);
      // console.log("HANDLE_ADD_USER_TO_TABLE");
      // return {
      //   ...state,
      //   tableItems: newTableItems,
      // };
    }
    case user.DELETE_USER: {
      console.log("DELETE_USER");
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);
      return { ...state, tableItems: newTableItems };
    }
    // case user.REMOVE_USER_ASSIGN: {
    //   const { result } = action.payload;
    //   console.log(result);
    //   var newTableItems = [...state.tableItems];
    //   console.log("newTableItems:", newTableItems);
    //   let index1 = newTableItems.findIndex((element) => {
    //     return element.id == result.id;
    //   });
    //   console.log("index: ", index1);
    //   let index2 = newTableItems[index1].assignedStock.findIndex((element) => {
    //     return element.id == result.projectId;
    //   });
    //   console.log("index(project): ", index2);
    //   console.log(newTableItems[index1].assignedStock);
    //   newTableItems[index1].assignedStock.splice(index2, 1);
    //   console.log(newTableItems[index1].assignedStock);
    //   return { ...state, tableItems: newTableItems };
    // }
    case user.UPDATE_USER_PERM: {
      const { result } = action.payload;
      return { ...state, accessPerm: result };
    }
    case user.TOGGLE_CREATE_USER: {
      const { toggle, isCreateScrapperUser} = action.payload;
      return {
        ...state,
        createUserToggle: toggle ? !state.createUserToggle : false,
        isCreateScrapperUser: isCreateScrapperUser || false,
      };
    }
    case user.TOGGLE_ADD_ASSIGNED_USER_TO_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        addAssignedUserToggle: toggle ? !state.addAssignedUserToggle : false,
      };
    }
    case user.TOGGLE_ADD_ASSIGNED_USER_TO_SALESTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        addAssignedUserToSaleStockToggle: toggle
          ? !state.addAssignedUserToSaleStockToggle
          : false,
      };
    }
    case user.TOGGLE_ADD_ASSIGNED_USER_TO_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        addAssignedUserToCustomerToggle: toggle
          ? !state.addAssignedUserToCustomerToggle
          : false,
      };
    }
    case user.TOGGLE_UPDATE_USER: {
      const { toggle, attachID, numberPlate } = action.payload;
      return {
        ...state,
        stockDataID: attachID,
        numberPlate,
        updateUserToggle: toggle ? !state.updateUserToggle : false,
      };
    }
    case user.TOGGLE_DELETE_USER: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteUserToggle: toggle ? !state.deleteUserToggle : false,
      };
    }
    case user.TOGGLE_DETAIL_USER: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailUserToggle: toggle ? !state.detailUserToggle : false,
      };
    }
    case user.RESET_USER_PARAMETERES: {
      return {
        ...state,
        ...initialFormState,
        permissions: JSON.parse(JSON.stringify(initialPermState)),
      };
    }
    case user.UPDATE_USER_PARAMETERS:
    case user.DELETE_USER_PARAMETERS:
    case user.DETAIL_USER_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case user.HANDLE_CHANGE_USER: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
