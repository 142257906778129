const stockStatusConf = {
  stock: [
    "BIDDING",
    "SOLD TO",
    "INSPECTED",
    "WCI - BID",
    "RCI",
    "AVAILABLE",
    "RETAILS - BIDDING",
    "SOLD",
    "REPAIRING",
    "REPAIR COMPLETE",
    "NC - CASH",
    "NC - LOAN(PD DOC)",
    "NC - LOAN(DOC RDY)",
    "NC - LOAN APPROVED",
    "SIGN LOAN & 2ND PAID - DONE",
    "CANCEL BOOKING",
    "SOLD - COMPLETE",
    "DF PENDING",
  ],
  biddingCar: ["BIDDING", "SOLD TO", "WCI - BID"],
  purchaser: ["INSPECTED", "WCI - BID", "RCI", "DF PENDING"],
  saleStock: ["AVAILABLE", "RETAILS - BIDDING", "SOLD"],

  carRepair: ["AVAILABLE", "REPAIR COMPLETE", "WCI - BID", "RCI"],
  salesPerson: ["SOLD"],
  paymentRequest: ["SOLD - COMPLETE"],
};

const allowMultiStatusConf = {
  stock: [
    "BIDDING",
    "SOLD TO",
    "INSPECTED",
    "WCI - BID",
    "RCI",
    "AVAILABLE",
    "RETAILS - BIDDING",
    "SOLD",
    "REPAIRING",
    "REPAIR COMPLETE",
    "NC - CASH",
    "NC - LOAN(PD DOC)",
    "NC - LOAN(DOC RDY)",
    "NC - LOAN APPROVED",
    "SIGN LOAN & 2ND PAID - DONE",
    "CANCEL BOOKING",
    "SOLD - COMPLETE",
    "DF PENDING",
  ],
  biddingCar: ["BIDDING", "SOLD TO", "WCI - BID"],
  purchaser: ["INSPECTED", "WCI - BID", "RCI", "DF PENDING"],
  saleStock: ["AVAILABLE"],
  carRepair: ["AVAILABLE", "REPAIR COMPLETE", "WCI - BID", "RCI"],
  salesPerson: ["SOLD"],
};

const wholesaleRetailStatus = {
  biddingCar: {
    WHOLESALE: ["BIDDING", "SOLD TO"],
    ALL: ["BIDDING", "SOLD TO"],
    RETAIL: ["BIDDING", "SOLD TO"],
  },
  saleStock: {
    RETAIL: ["AVAILABLE", "RETAILS - BIDDING", "SOLD"],
    ALL: ["AVAILABLE", "RETAILS - BIDDING", "SOLD"],
    WHOLESALE: ["AVAILABLE", "RETAILS - BIDDING", "SOLD"],
  },
  purchaser: {
    WHOLESALE: ["WCI - BID", "INSPECTED", "DF PENDING"],
    RETAIL: ["RCI"],
    ALL: ["WCI - BID", "INSPECTED", "RCI"],
  },
};

const stockStatusSecConf = {
  all: {
    INSPECTED: ["COMING SOON", "REJECTED", "TO BE CONFIRM"],
    RCI: [
      "PURCHASER",
      "FROM WHOLESALES",
      "TRADE IN",
      "BIDDING IN",
      "COMING IN",
      "OTHER",
      "WHOLESALES RETAILS",
    ],
    BIDDING: [
      "MTK - INSPECTED",
      "CS - INSPECTED",
      "TODAY BIDDING",
      "REBID - CS",
      "REBID - MTK",
      "REBID",
      "PENDING PRICE",
      "NO BUYER",
      "HOLD",
      "PREPARE CS",
      "PREPARE MTK",
      "SOLECAR RETAILS",
      "HOBBY RETAILS",
      "WTS",
    ],
    "SOLD TO": [
      "CARSOME",
      "MYTUKAR",
      "OTHER UCD",
      "PS",
      "SBA",
      "JOHOR",
      "KLANG",
      "GROUP",
      "WHOLESALES RETAIL",
      "HOLD",
      "SY RETAIL (CARWIFE)",
      "SOLECAR RETAIL",
      "GT SPORT",
      "HOBBY RETAILS",
      "YJ SPORT",
      "TT RETAIL",
      "JASON BONG RETAIL",
    ],
    SOLD: ["SOLD BY CASH", "SOLD BY LOAN (BANK)", "SOLD BY LOAN (CREDIT)"],
    AVAILABLE: ["RTS", "REPAIRING", "2ND PAYMENT DONE"],
  },
  carRepair: {
    RCI: ["BIDDING IN"],
    AVAILABLE: ["REPAIRING"],
  },
};

export {
  stockStatusConf,
  stockStatusSecConf,
  wholesaleRetailStatus,
  allowMultiStatusConf,
};
