export const GET_SETTING = "GET_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";

export const GET_SETTING_DATADASH = "GET_SETTING_DATADASH";
export const UPDATE_SETTING_DATADASH = "UPDATE_SETTING_DATADASH";

export const TOGGLE_UPDATE_SETTING = "TOGGLE_UPDATE_SETTING";
export const TOGGLE_DETAIL_SETTING = "TOGGLE_DETAIL_SETTING";

export const UPDATE_SETTING_PARAMETERS = "UPDATE_SETTING_PARAMETERS";
export const DETAIL_SETTING_PARAMETERS = "DETAIL_SETTING_PARAMETERS";

export const HANDLE_CHANGE_SETTING = "HANDLE_CHANGE_SETTING";
