import { setting } from "../actionTypes";
import modules from "../../helper/modules";

const initialFormState = {
  title: undefined,
  key: undefined,
  value: undefined,
  lastModifiedBy: undefined,
  updateAt: undefined,
};

const initialState = {
  rawItems: {},
  dropdownItems: [],
  visibleItems: [],
  isFields: true,
  updateSettingToggle: false,
  detailSettingToggle: false,
  ...initialFormState,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case setting.GET_SETTING: {
      const {
        dropdownItems,
        visibleItems,
        rawItems,
        lastModifiedBy,
        updateAt,
      } = action.payload;
      return {
        ...state,
        rawItems,
        dropdownItems,
        visibleItems,
        lastModifiedBy,
        updateAt,
      };
    }
    case setting.UPDATE_SETTING: {
      const { docItems, items, lastModifiedBy, updateAt } = action.payload;
      return {
        ...state,
        ...items,
        rawItems: { ...state.rawItems, ...docItems },
        lastModifiedBy,
        updateAt,
      };
    }

    case setting.GET_SETTING_DATADASH: {
      const { notice, lastModifiedBy, updateAt } = action.payload;
      return { ...state, notice, lastModifiedBy, updateAt };
    }
    case setting.UPDATE_SETTING_DATADASH: {
      const { notice, lastModifiedBy, updateAt } = action.payload;
      return { ...state, notice, lastModifiedBy, updateAt };
    }

    case setting.TOGGLE_UPDATE_SETTING: {
      const { toggle, isFields } = action.payload;
      return {
        ...state,
        updateSettingToggle: toggle ? !state.updateSettingToggle : false,
        isFields,
      };
    }
    case setting.TOGGLE_DETAIL_SETTING: {
      const { toggle, isFields } = action.payload;
      return {
        ...state,
        detailSettingToggle: toggle ? !state.detailSettingToggle : false,
        isFields,
      };
    }
    case setting.UPDATE_SETTING_PARAMETERS:
    case setting.DETAIL_SETTING_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case setting.HANDLE_CHANGE_SETTING: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
